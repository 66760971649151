export const questions = [
  {
    blue: "authentic, harmonious, compassionate",
    orange: "active, opportunistic, spontaneous",
    green: "versatile, inventive, competent",
    gold: "parental, traditional, responsible",
  },
  {
    blue: "unique, empathetic, communicative",
    orange: "competitive, impetuous, impactful",
    green: "curious, conceptual, knowledgeable",
    gold: "practical, sensible, dependable",
  },
  {
    blue: "devoted, warm, poetic",
    orange: "realistic, open-minded, adventuresome",
    green: "theoretical, seeking, ingenious",
    gold: "loyal, conservative, organized",
  },
  {
    blue: "tender, inspirational, dramatic",
    orange: "daring, impulsive, fun",
    green: "determined, complex, composed",
    gold: "concerned, procedural, cooperative",
  },
  {
    blue: "vivacious, affectionate, sympathetic",
    orange: "exciting, courageous, skillful",
    green: "determined, principled, rational",
    gold: "orderly, conventional, caring",
  },
];

