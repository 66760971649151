import './App.css';
import { questions as data } from './data.js';
import * as React from 'react';
//style={{ backgroundColor: color }}
const VALID_RESPONSE = new Set(['1', '2', '3', '4']);

function shuffleArray(arr) {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
}

// Each page load, shuffle the question order and the options order
shuffleArray(data);
let questions = [];
data.forEach(q => {
  let question = {};
  const keys = Object.keys(q);
  shuffleArray(keys);
  keys.forEach(key => {
    question[key] = q[key];
  });
  questions.push(question)
});

function eqSet(as, bs) {
  if (as.size !== bs.size) return false;
  for (var a of as) if (!bs.has(a)) return false;
  return true;
}

function getHighest(colors) {
  const { blue, green, gold, orange } = colors;
  if ( blue >= green && blue >= gold && blue >= orange ) {
    return "blue";
  } else if (green >= blue && green >= gold && green >=orange ) {
    return "green";
  } else if (gold >= blue && gold >= green && gold >= orange) {
    return "gold";
  } else {
    return "orange";
  }
}

function calculateColors(scoresByQuestion) {
  const totals = scoresByQuestion.reduce((acc, question, i) => {
    return {
      blue: acc.blue + parseInt(question.blue),
      orange: acc.orange + parseInt(question.orange),
      green: acc.green + parseInt(question.green),
      gold: acc.gold + parseInt(question.gold),
    }
  }, { blue: 0, orange: 0, green: 0, gold: 0 });

  return totals;
};

function getId(index, color) {
  return `${index}-${color}`;
}

function App() {
  const [color, setColor] = React.useState(null);
  const [colors, setColors] = React.useState(null);
  const [responses, setResponses] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [bgImageLink, setBackgroundLink] = React.useState(null);

  React.useEffect(() => {
    const resultsStr = sessionStorage && sessionStorage.getItem('results');
    if (resultsStr) {
      const results = JSON.parse(resultsStr);
      // TODO: restoring specific answers doesn't work because we shuffle them
      // results.forEach((colorObj, index) => {
      //   ['green', 'gold', 'orange', 'blue'].forEach(color => {
      //     const id = getId(index, color);
      //     console.log(document.getElementById(id), colorObj[color])
      //     document.getElementById(id).value = colorObj[color];
      //   });
      // });
      const colors = calculateColors(results);
      saveColors(colors);
    }
  }, []);

  // Render canvas download link when color results change
  React.useEffect(() => {
    if (!colors) { return }

    const colorMap = {
      orange: 'orange',
      blue: 'blue',
      green: 'green',
      gold: 'gold'
    };

    // Setup
    const totalScore = 50;
    const canvas = document.createElement('canvas');
    const CANVAS_WIDTH = 1920
    const CANVAS_HEIGHT = 1090;
    canvas.width = CANVAS_WIDTH;
    canvas.height = CANVAS_HEIGHT;
    var ctx = canvas.getContext('2d');
    ctx.font = "50px Arial";
    ctx.textBaseline = "middle";

    // Sort scores high to low
    const entries = Object.entries(colors).sort(([c1, scoreA], [c2, scoreB]) => 
      (scoreA < scoreB ? 1 : -1)
    );

    // Draw the stripes and text for each entry onto the canvas
    entries.reduce((sumScore, result) => {
      const [color, score] = result;
      const startY = sumScore / totalScore * CANVAS_HEIGHT;
      const height = score / totalScore * CANVAS_HEIGHT;

      ctx.fillStyle = colorMap[color];
      ctx.fillRect(0, startY, CANVAS_WIDTH, height);

      ctx.fillStyle = 'black';
      ctx.fillText(`${color}: ${score}`, 50, startY + height / 2);
      return sumScore + score;
    }, 0);
  
    setBackgroundLink(canvas.toDataURL('image/jpg', 0.85));
  }, [colors]);

  // input: [{blue: 2, orange: 1, green: 4, gold: 3}, {blue: 3, green: 4}...]
  function validate(scoresByQuestion) {
    if (scoresByQuestion.length < questions.length) {
      setErrorMessage('Please score each question')
      return false;
    }
    const invalidEntries = scoresByQuestion.filter(scores => {
      const vals = new Set(Object.values(scores));
      return !eqSet(vals, VALID_RESPONSE);
    });

    if (invalidEntries.length) {
      setErrorMessage('Please score each row 1-4 (think Sudoku).');
    }

    return !invalidEntries.length;
  }

  const handleScoreChange = (e) => {
    setResponses({
      ...responses,
      [e.target.id]: e.target.value
    });
  };

  function clear(e) {
    e.preventDefault();
    document.querySelectorAll('input').forEach(e => e.value = '');
    window.sessionStorage.clear();
    saveColors(null);
  }

  function saveColors(colors) {
    setColors(colors);
    setColor(colors ? getHighest(colors) : null);
    setErrorMessage(null);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const scoresByQuestion = formatResponses();
    const valid = validate(scoresByQuestion);

    if (valid) {
      console.log(scoresByQuestion);
      window.sessionStorage.setItem('results', JSON.stringify(scoresByQuestion));
      const colors = calculateColors(scoresByQuestion);
      saveColors(colors);
      return;
    }
  }

  // responses shape: {1-blue: '1', 1-orange: '2', 1-green: '3', 1-gold: '4',..}
  // acc shape: [{blue: 1, orange: 2, green: 3, gold: 4 }, {blue: 2, green: 3}...]
  function formatResponses() {
    const scores = Object.keys(responses);

    const scoresByQuestion = scores.reduce((acc, score, i) => {
      const questionId = score.charAt(0).toString();
      const color = score.slice(2);
      const value = responses[score];

      const currentQuestionResponses = acc[questionId] || {};
      currentQuestionResponses[color] = value;
      acc[questionId] = currentQuestionResponses;

      return acc;
    }, []);

    return scoresByQuestion;
  }

  const renderQuestion = (colors, index) => {
    return (
      <div key={index}>
        <fieldset className="App-quiz-question">
        <legend>Rating Set {index + 1}</legend>
          {
            Object.keys(colors).map(color => {
              const id = getId(index, color);
              return (
                <div className="App-quiz-answer" key={color}>
                  <label htmlFor={id}>{colors[color]}</label>
                  <input placeholder="1, 2, 3, or 4" onChange={handleScoreChange} type="text" id={id}/>
                </div>
              );
            })
          }
        </fieldset>
      </div>
    )
  }

  return (
    <div className="App">
      <div className={color}>
        <header className="App-header">
          <p>True Colors Evaluation</p>
        </header>
        <section className="App-body" >
          <p>Score across rows: 4 for the group in the row that’s most like you, 3 for the next most like you, etc.<br />
          When you’re done, hit submit.</p>
          <form>
            <div className="App-quiz">
              { questions.map(renderQuestion) }
            </div>
            <div role="alert" className="message-error">{errorMessage}</div>
            <button className="button" onClick={clear}>Reset</button>
            <button className="button button-submit" onClick={handleSubmit}>Submit</button>
          </form>
        </section>

        { color && !errorMessage ?
          <div className="message-color" role="alert">
            <p><strong>Your primary color is {color}!</strong></p>
            <p>Your scores were: Blue: {colors.blue}, Orange: {colors.orange}, Gold: {colors.gold}, Green: {colors.green}</p>
          </div>
        : null }
        { bgImageLink && <a download="colors-meet-bg.jpg" href={bgImageLink}>Download Google Meet background</a>}
        </div>
    </div>
  );
}

export default App;
